






















import { defineComponent, SetupContext, ref, onMounted } from '@vue/composition-api'
import { Vue } from 'vue/types/vue'
import DatePicker from 'vue2-datepicker/index'
import 'vue2-datepicker/locale/pl'

import { dateTimePickerProps, useDateTimePicker } from './DateTimePicker.hooks'
import { DateTimePickerProps } from './DateTimePicker.contracts'
import CalendarIcon from './CalendarIcon.vue'

/**
 * Component capable to render DateTime picker atom.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const DateTimePicker = defineComponent({
  name: 'DateTimePicker',
  components: {
    CalendarIcon,
    DatePicker
  },
  props: dateTimePickerProps,

  setup (props: DateTimePickerProps, ctx: SetupContext) {
    const datePicker = ref<Vue | null>(null)

    onMounted(() => {
      // Add required attribute to DateTimePicker Input.
      if (props.required && datePicker && datePicker.value) {
        const input = datePicker.value.$refs.input as HTMLInputElement
        input.setAttribute('required', 'true')
      }
    })

    return {
      ...useDateTimePicker(props, ctx),
      datePicker
    }
  }
})
export default DateTimePicker
